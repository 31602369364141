import { UserManager } from 'oidc-client-ts'

const settings = {
    authority: process.env.VUE_APP_IDENTITYSERVER_AUTHORITY, // URL do IdentityServer
    client_id: process.env.VUE_APP_IDENTITYSERVER_CLIENTE_ID,
    redirect_uri: 'http://localhost:8080/callback',
    response_type: 'code',
    scope: process.env.VUE_APP_IDENTITYSERVER_SCOPE,
    post_logout_redirect_uri: 'http://localhost:8080/logout',
}

const userManager = new UserManager(settings)

export default {
  // Método para iniciar o login
  async login(returnUrl) {
    const user = await userManager.getUser()
    if (!user) {
      return await userManager.signinRedirect(returnUrl)
    } 
    return await userManager.signinRedirect()
  },

  // Método para lidar com o callback após login
  signinCallback() {
    return userManager.signinRedirectCallback();
  },

  // Método para iniciar o logout
  logout() {
    return userManager.signoutRedirect();
  },

  // Método para obter o usuário autenticado
  getUser() {
    return userManager.getUser();
  },

  // Método para renovar o token silenciosamente
  renewToken() {
    return userManager.signinSilent();
  },
}

