import admRoutes from './routes.adm'
import siteRoutes from './routes.site'
import defaultRoutes from './routes.default'

const routes = [
  ...admRoutes,
  ...siteRoutes,
  ...defaultRoutes,
];

export default routes