import Vue from 'vue'
import Vuex from 'vuex'

// import auth from './modules/auth'
import notification from './modules/notification'
import authOidc from './modules/authOidc'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    authOidc,
    notification
  },
  strict: debug,
  plugins: []
})