const metaDefault = {
    requiresAuth: false,
    roles: []
}
import AuthService from '@/services/oidcClient'

export default [
    {
        path: '/login',
        name: 'Login',
        //component: () => import('../views/Login.vue'),
        component: () => import('@/components/Auth/LoginAuth/index.vue'),
        meta: metaDefault,
    },
    {
        path: '/callback',
        name: 'callback',
        async beforeEnter(to, from, next) {
          // Processa o callback de login
          console.log("aqui")
          await AuthService.signinCallback()
          next('/')
        },
    },
    {
        path: '*',
        redirect: '/login'
    }
]