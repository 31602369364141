import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const isAuthenticated = store.getters['authOidc/isAuthenticated']
  const userRole = store.getters['authOidc/userRole']
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  //Se a rota exigir autenticação
  if (requiresAuth) {
    
    if (to.path === '/login' && from.path === '/login') {
      return next(false); // Evita navegação redundante
    }

    if (!isAuthenticated) {
      next({ path: '/login' })
    } else {
      // Se a rota exigir uma role específica
      // if (to.matched.some(record => record.meta.roles)) {
      //   const allowedRoles = to.meta.roles
        
      //   if (allowedRoles.includes(userRole)) {
      //     next()
      //   } else {
      //     next({ path: '/gerencial' })
      //   }
      // } else {
        next()
      //}
    }
  } else {
    next()
  }

  //const permissions = ['Avancado']

  // if (to.matched.some(record => record.meta.requiresAuth)) {

  //   if (store.getters['auth/isLoggedIn'] && permissions.indexOf(store.getters['auth/userTipo']) != -1)
  //     return next()
  //   else
  //     return next('/login')
  // } else
  //   return next()

  //return next()
})

export default router