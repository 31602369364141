// Validação de parâmetros de query
function validateQueryParams(query) {
    const { pedido_rid, invoice_rid } = query
    // Exemplo de validação: verificar se os parâmetros estão presentes e são strings não vazias
    if (typeof pedido_rid === 'string' && pedido_rid !== '' &&
        typeof invoice_rid === 'string' && invoice_rid !== '') {
        return true
    }
    return false
}

const metaDefault = {
    requiresAuth: false,
    roles: []
}

const metaCliente = {
    requiresAuth: true,
    roles: ['Client']
}

export default [
    {
        path: '/site',
        name: 'Site',
        component: () => import('../views/Site/SiteLayout.vue'),
        meta: metaDefault,
        children: [
            {
                path: '/site/customer-panel-auth',
                name: 'Auth Cliente',
                component: () => import('../components/Site/PainelCliente/AuthCliente/index.vue'),
                meta: metaDefault,
            },
            {
                path: '/site/customer-panel',
                name: 'Painel Cliente',
                component: () => import('../views/Site/PainelClienteLayout.vue'),
                meta: metaCliente,
                redirect: '/site/customer-panel/invoices',
                children: [
                    {
                        path: '/site/customer-panel/invoices',
                        name: 'Faturas Cliente',
                        component: () => import('../components/Site/PainelCliente/FaturasCliente/index.vue'),
                        meta: metaCliente,
                    },                    
                    // {
                    //     path: '/site/customer-panel/order-payment',
                    //     name: 'Pagamento pedido conta receber',
                    //     component: () => import('../components/Site/PainelCliente/PaymentFatura/index.vue'),
                    //     meta: metaCliente,
                    // },
                ]
            },
            {
                path: '/site/customer-panel/order-payment',
                name: 'Pagamento pedido conta receber',
                component: () => import('../views/Site/CheckoutPayLayout.vue'),
                meta: metaDefault,
            },
            // {
            //     path: '/site/pedido-payment/:rid',
            //     name: 'Pagamento pedido',
            //     component: () => import('../components/Site/PedidoPagamento/index.vue'),
            //     meta: metaDefault,
            // },
            {
                path: '/site/pedido-payment/:rid/:conta_receber',
                name: 'Pagamento pedido conta receber',
                component: () => import('../components/Site/PedidoPagamentoFatura/index.vue'),
                meta: metaDefault,
            },
            {
                path: '/site/order-payment-cielo',
                name: 'Pagamento pedido Cielo',
                component: () => import('../components/Site/PaymentCieloCurso/index.vue'),
                beforeEnter: (to, from, next) => {
        
                    const isValid = validateQueryParams(to.query)

                    if (isValid) {
                        next()
                    } else {
                        next('/login')
                    }
                },
                meta: metaDefault,
            },
        ]
    },
    {
        path: '/site/calculadora-de-preco-justo-de-acao',
        name: 'Calculadora de Preço Justo de Ação',
        component: () => import('../views/Site/CalcSiteLayout.vue'),
        meta: metaDefault,
    },
    {
        path: '/site/download-certificado-curso/:rid',
        name: 'Download Certificado Curso',
        component: () => import('../views/Site/CertificadoLayout.vue'),
        meta: metaDefault,
    },
]