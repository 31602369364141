import ApiService from "../api.service"

export default class PainelClientService {
    static async ListFaturasPaid(){
        return await ApiService.chamaApiV2(`v2/api/painel-cliente/faturas-paid`)
    }
    static async ListFaturasPedding(){
        return await ApiService.chamaApiV2(`v2/api/painel-cliente/faturas-pedding`)
    }
    
    static async CustomerInfo(){
        return await ApiService.chamaApiV2(`v2/api/painel-cliente/customer-info`)
    }

}