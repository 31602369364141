import axios from 'axios'
import Cookies from 'js-cookie'

const authConfig = {
    authority: process.env.VUE_APP_IDENTITYSERVER_AUTHORITY, // URL do IdentityServer
    client_id: process.env.VUE_APP_IDENTITYSERVER_CLIENTE_ID,
    client_secret: process.env.VUE_APP_IDENTITYSERVER_CLIENTE_SECRET,
    scope: process.env.VUE_APP_IDENTITYSERVER_SCOPE,
    redirectUri: 'http://localhost:8080/callback'
}

export default class AuthService {

    static async login(email,password){
    
        const dataObject = new URLSearchParams()

        dataObject.append('grant_type', 'password')
        dataObject.append('username', email)
        dataObject.append('password', password)
        dataObject.append('scope', authConfig.scope)
        dataObject.append('client_id', authConfig.client_id)
        dataObject.append('client_secret', authConfig.client_secret)

        const requestOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache'
            },
            method: 'POST',
            body: dataObject
        }
        
        const response = await axios.post(`${authConfig.authority}/connect/token`, dataObject, requestOptions)

        return response.data
    }

    static async loginClient(email,password){
    
        const dataObject = new URLSearchParams()

        dataObject.append('grant_type', 'password')
        dataObject.append('username', email)
        dataObject.append('password', password)
        dataObject.append('scope', authConfig.scope)
        dataObject.append('client_id', process.env.VUE_APP_IDENTITYSERVER_CLIENTE_ID_PANEL)
        dataObject.append('client_secret', process.env.VUE_APP_IDENTITYSERVER_CLIENTE_SECRET_PANEL)

        const requestOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache'
            },
            method: 'POST',
            body: dataObject
        }
        
        const response = await axios.post(`${authConfig.authority}/connect/token`, dataObject, requestOptions)

        return response.data
    }

    static async changePassword(currentPassword,nwpasswd){
    
        const userProfile = Cookies.get('user_profile') ? JSON.parse(Cookies.get('user_profile')) : null

        const dataObject = {
            UserId: userProfile.id,
            CurrentPassword: currentPassword,
            NewPassword: nwpasswd
        }

        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            },
            method: 'POST',
            body: dataObject
        }
        
        const response = await axios.post(`${authConfig.authority}/api/auth/change-password`, dataObject, requestOptions)

        return response.data
    }


    logout() {
        return userManager.signoutRedirect()
    }
}